<template>
  <div class="v_change_id_card g-flex-column">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>身份证信息</span>
      </template>
    </head-back>

    <div class="v-change-info-content">
      <div class="v-change-info-form">
        <div class="v-change-info-form-item">
           <van-field v-model="form.name" label="姓名:" placeholder="请输入姓名" clearable/>
        </div>
        <div class="v-change-info-form-item">
          <div class="v-change-info-form-item-box g-flex-align-center">
            <span class="v-change-info-form-item-label">性别:</span>
            <van-radio-group v-model="form.sex" direction="horizontal" checked-color="#F9436B">
              <van-radio :name=1>男</van-radio>
              <van-radio :name=2>女</van-radio>
            </van-radio-group>
          </div>

        </div>
        <div class="v-change-info-form-item">
           <van-cell title="出生日期:" class="v-change-info-form-item-date-cell" is-link  :value="form.birthday ? formatDate(form.birthday, 'yyyy-MM-dd') : this.placeholderdateValue " @click="dateClick"/>
        </div>
         <div class="v-change-info-form-item">
          <van-cell title="住址:" class="v-change-info-form-item-date-cell" is-link  :value="form.region ? form.region : '请选择地区'" @click="addressClick"/>
        </div>

        <div class="v-change-info-form-item">
           <van-field v-model="form.address" label="详细地址:" placeholder="请输入详细地址" clearable/>
        </div>

        <div class="v-change-info-form-item">
           <van-field v-model="form.card_id" label="公民身份证号:" maxlength="18" placeholder="请输入18位公民身份证号" clearable/>
        </div>

        <div class="v-change-info-form-item">
           <van-field v-model="form.qq" label="QQ号码:" placeholder="请输入QQ号码" clearable/>
        </div>

        <div class="v-change-info-form-item">
           <van-field v-model="form.wechat" label="微信号:" placeholder="请输入微信号" clearable/>
        </div>
      </div>
      
      <div class="v-change-info-upload-list g-flex-align-center">
        <change-id-card-upload :imgUrl="form.card_front" :direction="1" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg"/>
        <change-id-card-upload :imgUrl="form.card_back" :direction="0" @emitUploadSuccess="emitUploadSuccess" @emitDeleteUploadImg="emitDeleteUploadImg"/>
      </div> 

      <div class="v-change-info-no-pass g-flex-align-center" v-if="form.real_status == 3">
        <span>审核不通过理由:</span>
        <p>{{form.real_remark}}</p>
      </div>

      <div class="v-change-info-btn">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"/>
      </div>
    </div>

    <change-address ref="ChangeAddress" @emitAddress="emitAddress"/>

    <change-date ref="ChangeDate" @emitDatePicker="emitDatePicker"/>


  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import ChangeAddress from '../../components/ChangeAddress.vue'
  import ChangeDate from '../../components/ChangeDate.vue'
  import { formatDate } from '../../utils/index.js'
  import OperationButton from '../../components/OperationButton.vue'
  import ChangeIdCardUpload from './ChangeIdCardUpload.vue'
  import { apiUserRealName, apiGetUserInfo } from '@/utils/api.js'

  export default {
    components: { HeadBack, ChangeAddress, ChangeDate, OperationButton, ChangeIdCardUpload },
    data() {
      return {
        value: '',
        radio: 1,
        placeholderdateValue: '请选择出生日期',
        addressValue: '请选择住址',
        form: {
          name:'',
          areaCode: '350000',
          sex: 1,
          birthday: '',
          wechat: '',
          qq: '',
          province: '',
          city: '',
          area: '',
          region: '',
          address: '',
          card_id: '',
          card_front: '',
          card_back: '',
          real_remark: ''
        },
        buttonObj: {
          claSS: 'c-btn-default',
          title: '确认提交',
          event: 'submitClick'
        }
      }
    },
    created() {
      let localUserInfo = this.$global.userInfo
      if(localUserInfo) {
        this.form.name = localUserInfo.name
        this.form.birthday = Number(localUserInfo.birthday)
        this.form.sex = localUserInfo.sex
        this.form.qq = localUserInfo.qq
        this.form.wechat = localUserInfo.wechat
        this.form.address = localUserInfo.address
        this.form.region = localUserInfo.region
        this.form.province = localUserInfo.province
        this.form.city = localUserInfo.city
        this.form.area = localUserInfo.area
        this.form.areaCode = localUserInfo.area
        this.form.card_id = localUserInfo.card_id
        this.form.card_front = localUserInfo.card_front
        this.form.card_back = localUserInfo.card_back
        this.form.real_remark = localUserInfo.real_remark
        this.form.real_status = localUserInfo.real_status
      }
    },
    methods: {
      formatDate,
      dateClick() {
        this.$refs.ChangeDate.onShow(true, this.form.birthday)
      },
      addressClick() {
        this.$refs.ChangeAddress.onShow(true, this.form.areaCode)
      },

      emitDatePicker(time) {
        console.log(time)
        this.form.birthday = parseInt(time / 1000) 
      },

      emitAddress(list) {
        console.log(list)
        this.form.region = ''
        this.form.areaCode = list[list.length-1].code
        this.form.province = list[0].code
        this.form.city = list[1].code
        this.form.area = list[2].code
        this.form.region = list[0].name + '-' + list[1].name +  '-' + list[2].name
      },

      emitClickItem(event) {
        if(!event) return
        this[event]()
      },
      
      // 获取用户信息
      async apiGetUserInfoHandel() {
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.$global.setUserInfo(data.data)
      },

      // 实名
      async apiUserRealNameHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiUserRealName(this.form).catch(() => this.$global.loadingShow = false )
        this.$global.loadingShow = false 
        if(!success) return 
        this.$toast(data.msg)
        this.apiGetUserInfoHandel()
        setTimeout(() => {
          this.$router.replace({ name: 'safecenter' })
        }, 500);
        console.log(data)
      },

      submitClick() {
        if(!this.form.name) return this.$toast('请填写姓名')
        if(!this.form.birthday) return this.$toast('请填写出生日期')
        if(!this.form.region) return this.$toast('请选择地区')
        if(!this.form.address) return this.$toast('请填写地址')
        if(!this.form.wechat) return this.$toast('请填写微信')
        if(!this.form.qq) return this.$toast('请填写QQ')
        if(!this.form.card_id) return this.$toast('请填写身份证号码')
        if(!this.form.card_front) return this.$toast('请上传身份证正面')
        if(!this.form.card_back) return this.$toast('请上传身份证反面')
        this.apiUserRealNameHandel()
        console.log('提交')
      },

      // 触发
      emitUploadSuccess(obj) {
        if(obj.type === 1) this.form.card_front = obj.url
        if(obj.type === 0) this.form.card_back = obj.url
      },

      // 触发
      emitDeleteUploadImg(obj) {
        if(obj.type === 1) this.form.card_front = ''
        if(obj.type === 0) this.form.card_back = ''
      }
    }
  }
</script>

<style lang="scss">
.v_change_id_card {
  height: 100%;
  background: #F1F1F1;
  .v-change-info-content {
    flex: 1;
    overflow: auto;
    padding: 10px;
    .v-change-info-form {
      background: $white;
      border-radius: 8px;
      overflow: hidden;
      .v-change-info-form-item {
        .v-change-info-form-item-box {
          padding: 10px 16px;
          .v-change-info-form-item-label {
            flex: 1;
            color: #646566;
            text-align: left;
            font-size: 14px;
          }
          .van-radio-group {
            flex: 2;
            font-size: 14px;
          }
        }

        .van-cell {
          .van-cell__title  {
            margin-right: 0;
            flex: 1;
            color: #646566;
          }
          .van-cell__value {
            flex: 2;
            text-align: left;
            .van-field__control {
              color: #969799;
            }
          }
        }

        .v-change-info-form-item-date-cell {
          .van-cell__value {
            padding-left: 10px;
          }
        }
      }
    }
    .v-change-info-upload-list {
      padding-top: 20px;
    }
    .v-change-info-no-pass {
      padding-top: 20px;
      font-size: 14px;
      p {
        flex: 1;
        color: red;
        font-size: 18px;
        padding-left: 10px;
      }
    }
  }
  .v-change-info-btn {
    margin-top: 20px;
  }
}
</style>