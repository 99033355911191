<template>
  <div class="s_change_id_card_upload g-flex-align-center g-flex-justify-center">
    <van-uploader :before-read="beforeRead" :after-read="afterRead">
      <div class="s-change-id-card-img-box g-flex-column g-flex-align-center">
        <img v-show="!userAvator" :src="direction ? directDefaultImg : obverseDefaultImg" alt="" />
        <div class="s-change-id-card-img-relative" v-show="userAvator">
          <img :src="userAvator" alt="" @click.stop="previewImg(userAvator)"/>
          <div class="s-change-id-card-delete" @click.stop="deleteUploadImg">
            <i class="iconfont icon-guanbi"></i>
          </div>
        </div>
        <span class="s-change-id-card-default-text">{{direction? '请上传身份证正面': '请上传身份证背面' }}</span>
      </div>
    </van-uploader>
  </div>
</template>

<script>
  import { apiUpLoadHeadImg } from '../../utils/api.js'
  import { ImagePreview } from 'vant';
  export default {
    props: {
      imgUrl: {
        type: String,
        default() {
          return ''
        }
      },
      // 0 反面 1正面
      direction: {
        type: Number,
        default() {
          return 0
        }
      }
    },
    data() {
      return {
        limitPng: ['image/jpeg','image/jpg','image/png','image/JPEG','image/JPG','image/PNG'],
        userAvator: '',
        directDefaultImg: require('../../assets/img/direct.png'),
        obverseDefaultImg: require('../../assets/img/obverse.png'),
      }
    },
    methods: {
      previewImg(img) {
        console.log('预览')
        ImagePreview({
          images: [img], // 预览图片的那个数组
          showIndex:true,
          loop:false,
      })
      },
      // 上传之前
      beforeRead(file) {
        if(this.limitPng.indexOf(file.type) === -1) {
          this.$toast('请上传jpeg、jpg、png格式的图片')
          return false
        }
        return true
      },

      // 上传
      async afterRead(file) {
        // formdata提交
        let formData = new FormData()
        formData.append('file', file.file)
        this.$global.loadingShow = true
        const { success, data } = await apiUpLoadHeadImg(formData)
        if(!success) return false
        this.userAvator = data.url
        this.$toast(data.msg)
        this.$emit('emitUploadSuccess', { url: this.userAvator, type: this.direction })
      },

      // 删除
      deleteUploadImg() {
        this.userAvator = ''
        this.$emit('emitDeleteUploadImg', { type: this.direction })
      }
    },
    watch: {
      imgUrl: {
        handler(nval) {
          if(nval) {
            this.userAvator = nval
          } 
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss">
.s_change_id_card_upload {
  flex: 1;
  .van-uploader {
    .s-change-id-card-img-box {
      img {
        width: 170px;
        height: 90px;
        object-fit: cover;
        border-radius: 3px;
      }
      .s-change-id-card-img-relative {
        position: relative;
        z-index: 99;
        .s-change-id-card-delete {
          position: absolute;
          right: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.7);
          padding: 3px;
          border-radius: 0 0 0 10px;
          overflow: hidden;
          z-index: 99;
          .icon-guanbi {
            font-size: 18px;
            color: $white;
          }
        }

      }
      .s-change-id-card-default-text {
        color: #6E6E6E;
        font-size: 16px;
        padding-top: 12px;
      }

    }
  }
}
</style>